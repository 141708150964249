import React from 'react';

import './skeleton.css';
/**
 * shadow = the object which the skeleton is skeleton-ing for (appears if undefined)
 * variant = driver/constructor
 * input = true/false
 * responses = true/false
 */
function SkeletonAnswer({shadow, variant, input, responses}) {
	return (
		<div className='dark-card skeleton answer'>
			<div className='main'>
				{input===true && (
				<div className='input'></div>
				)}
				<div className='flex-grow-1 align-self-center flex gap-1'>
					{(variant === 'driver' || variant === 'constructor') && (
					<div className='image'></div>
					)}
					<div className='text'></div>
				</div>				
				<div className='points'></div>
			</div>

			{responses===true && (
			<div></div>
			)}
		</div>
	);
}
export default SkeletonAnswer;