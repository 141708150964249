import React from 'react';

import './skeleton.css';
/**
 * shadow = the object which the skeleton is skeleton-ing for (appears if undefined)
 * variant = driver/constructor
 * input = true/false
 * responses = true/false
 */
function SkeletonQuestion({shadow, variant, input, responses}) {
	return (
		<div className='dark-card skeleton question'>
			<div className='main'>
				<div className='header-text'></div>
			</div>
		</div>
	);
}
export default SkeletonQuestion;