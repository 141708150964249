import React, { useState, useEffect } from 'react';
import UserChip from '../UserChip/UserChip';
import SkeletonAnswer from '../Skeleton/SkeletonAnswer';
import Button from '../../atoms/Button/Button';
import Chip from '../../atoms/Chip/Chip';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';

import './Answer.css';

/**
 * answer===undefined : skeleton state
 * answer==={} : empty state
 * answer===answer : answer state
 */
const Answer = ({ answer, variant, inputClick, disabled, responsesClick }) => {

    const handleInputClick = () => {
        inputClick(answer);
    }    
      
  return (<>

        {answer===undefined ? (
        <SkeletonAnswer input={true}></SkeletonAnswer>
        ) : (    
        <div variant={variant} className={`answer-container 
            ${variant === undefined ? '' : 'dark-card'}
            ${answer?.imageUrl !== undefined && answer?.imageUrl !== '' && 'background-image-cover'}
            ${answer?.userAnswer!==undefined ? 'selected' : ''}`}
                    
            style={answer?.imageUrl !== undefined && answer?.imageUrl !== '' ? {backgroundImage: `linear-gradient(to bottom, 
                        rgba(0, 0, 0, 0) 30%, 
                        var(--dark-4)), 
                        url(${answer.imageUrl})
                        `} : {}}>

            <div className="main">
                <div className="details answer">

                    {variant==='input-toggle' && (
                    <div className='input'>
                        {Object.keys(answer).length !== 0 && (
                        <Button variant='icon-inline' onClick={handleInputClick} disabled={disabled}><ChangeCircleIcon fontSize='small' /></Button>
                        )}
                            
                        {Object.keys(answer).length === 0 && (
                        <Button variant='primary' onClick={handleInputClick} disabled={disabled}>Pick</Button>
                        )}                  
                    </div>
                    )}

                    {variant==='input-pick' && (
                    <div className='input'>
                        <Button variant='primary' onClick={handleInputClick} disabled={disabled}>Pick</Button>
                    </div>
                    )}

                    {Object.keys(answer).length !== 0 && (<>
                    <div className='flex gap-05'>{answer.text}</div>
                    </>)}
                    
                    {Object.keys(answer).length === 0 && (
                    <div>Select Answer</div>
                    )}
                </div>
                
                {Object.keys(answer).length !== 0 && variant !== undefined && (<>
                <div className='points'>
                    <Chip>{answer?.points}</Chip>
                </div>
                </>)}
                

            </div>
            
                    
            {variant==='responses' && (<>
            <div className="submission-avatars" onClick={(e) => responsesClick(e, answer.submissions)}>
                {answer.submissions.map(user => (
                <UserChip variant='avatar' user={user}></UserChip>
                ))}
            </div>
            </>)}

        </div>
        )}
    </>);
}

export default Answer;
