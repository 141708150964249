import React, { useState, useEffect } from 'react';
import Button from '../../atoms/Button/Button';
import Modal from '../../atoms/Modal/Modal';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Chip from '../../atoms/Chip/Chip';
import './ConstructorStats.css';

const ConstructorStats = ({config, onHide}) => {
    const [constructor, setConstructor] = useState({});
    
    useEffect(() => {
        if(config.show) {
            console.log('config', config);
            setConstructor(config.constructor);
        }

    }, [config]);

    return (
    <Modal show={config.show} onHide={onHide} closeOnOverlayClick={true}>

        {constructor.lastStats !== null && constructor.lastStats !== undefined && (
        <div className='dark-card'>
            <div className='flex justify-content-between align-items-center'>
                <h4>Previous Race</h4>
                <Chip>{constructor.lastStats.points}</Chip>
            </div>

            <p>
                Pit Stops: {constructor.lastStats.pitStopCount}<br /> 
            </p>

            {constructor.lastStats.fastestPitStopPoints>0 && (
            <div className='flex gap-05'>
                <Chip>Fastest Pit</Chip>
            </div>
            )}
        </div>
        )}

        {constructor.currentStats !== null && constructor.currentStats !== undefined && (
        <div className='dark-card'>
            <div className='flex justify-content-between align-items-center'>
                <h4>This Race</h4>
                <Chip>{constructor.currentStats.points}</Chip>
            </div>
            
            {constructor.currentStats.pitStopCount!==null && (
            <p>
                Pit Stops: {constructor.currentStats.pitStopCount}<br /> 
            </p>
            )}
            
            <div className='grid gap-1'>
                {constructor.driver.map(d => (
                <div className='dark-card'>
                    <div className="driver-answer">
                        <img className="answer-image small" src={d.profilePicUrl} alt="answer-img" />
                        <div>
                            <h4>{d.lastName}</h4>
                            
                            <p>
                                {d.endPosition===null && (<>
                                    <span>Starting: P{d.startPosition}</span>
                                </>)}
                                
                                {d.endPosition!==null && (<>
                                
                                    {d.status===null && (<>
                                        <span>Finished: P{d.endPosition} </span>
                                        {d.changePosition>0 && (
                                            <span style={{color: 'green'}}>(<ArrowDropUpIcon fontSize='small' />{d.changePosition})</span>
                                        )}
                                        {d.changePosition===0 && (
                                            <span>(-)</span>
                                        )}
                                        {d.changePosition<0 && (
                                            <span style={{color: 'red'}}>(<ArrowDropDownIcon fontSize='small' />{Math.abs(d.changePosition)})</span>
                                        )}
                                    </>)}
                                    
                                    {d.status!==null && (
                                    <div className='flex gap-05 align-items-center'>
                                        <div>Finished:</div>
                                        <Chip>{d.status}</Chip>
                                    </div>
                                    )}
                                </>)}
                            </p>
                        </div>
                    </div>
                </div>  
                ))}
            </div>
            
            {constructor.currentStats.fastestPitStopPoints>0 && (
            <div className='flex gap-05'>
                <Chip>Fastest Pit</Chip>
            </div>
            )}
        </div>
        )}
    </Modal>

    );
}

export default ConstructorStats;
