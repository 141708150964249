import React, { useReducer, useState, useEffect } from 'react';
import emailjs from '@emailjs/browser';

import Button from '../../../atoms/Button/Button';
// import Dropdown from 'react-bootstrap/Dropdown';

import Loader from '../../../components/Loader/Loader';
import PictureChooser from '../../../components/PictureChooser/PictureChooser';
import UserChip from '../../../components/UserChip/UserChip';

import SaveIcon from '@mui/icons-material/Save';
import EmailIcon from '@mui/icons-material/Email';
import './UsersTab.css';

emailjs.init('process.env.EMAIL_JS_USER_ID');

function reducer(state, action) {
    console.log(`reducer (${action.type}, ${action?.payload?.index})`);   
    
    let newState = structuredClone(state);
    try {

        let i = action?.payload?.index;

        switch(action.type) {
            case 'initialize':
                return action.payload;

            case 'add_user':
                newState.push({
                    id: null,
                    firstName: '',
                    lastName: '',
                    displayName: '',
                    color: '',
                    email: '',
                    avatarUrl: '',
                    isAdmin: false,
                    active: true
                });
                return newState;

            case 'update_user':
                if(action.payload.property === 'First Name') {
                    newState[i].firstName = action.payload.value;
                    
                } else if(action.payload.property === 'Last Name') {
                    newState[i].lastName = action.payload.value;
                    
                } else if(action.payload.property === 'Display Name') {
                    newState[i].displayName = action.payload.value;

                } else if(action.payload.property === 'Avatar URL') {
                    newState[i].active = action.payload.value;

                } else if(action.payload.property === 'Color') {
                    newState[i].color = action.payload.value;

                } else if(action.payload.property === 'Email') {
                    newState[i].email = action.payload.value;

                } else if(action.payload.property === 'Admin') {
                    newState[i].isAdmin = action.payload.value;

                } else if(action.payload.property === 'Active') {
                    newState[i].active = action.payload.value;
                } else {
                    console.log(`Property ${action.payload.property} not known.`);
                }
                return newState;

            default:
                console.log(`Action ${action.type} not known.`);
                return newState;
        }
    }
    catch (error) {
        console.error(`An error occurred (${action.type}):`, error);
        return newState;
    }
}



function UsersTab() {
    const [loader, setLoader] = useState({show: false, text: ''});
    const [pictureChooserConfig, setPictureChooserConfig] = useState({show: false});

    const [fetchedData, setFetchedData] = useState(false);
    const [users, dispatch] = useReducer(reducer, []);
    const [dbData, setDbData] = useState();


    useEffect(() => {
        console.log('useEffect UserTab');
        if(!fetchedData) {
            fetchUserData();
        }
    }, [users]);

    const fetchUserData = () => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        setLoader({show: true, text: 'Loading All Users...'});
        fetch(apiUrl + `/user/get/all`)
          .then(response => response.json())
          .then(data => {
              setFetchedData(true);
              setDbData(data);
              dispatch({type: 'initialize', payload: data});
              setLoader({show: false});
      })
          .catch((err) => {
            console.log(err);
            setLoader({show: false});
          });
    }

    const openPictureChooser = (index) => {
        setPictureChooserConfig({show: true, index: index});
    }

    const closePictureChooser = (url) => {
        console.log(`url (${url.length})`, url);
        
        if(url.length > 0) {
            let index = pictureChooserConfig.index;
            
            dispatch({type: 'update_user', payload: {index, property: 'Avatar URL', value: url}});
        }
        
        setPictureChooserConfig({show: false});
    }

    const handleUserChange = (property, value, i) => {
        console.log(`handleUserChange [${property}] => ${value} [${i}]`);
        
        dispatch({type: 'update_user', payload: {index: i, property: property, value: value}});
    }

    const handleInviteClick = (u) => {
        const templateParams = {
            user_email: u.email,
        };
    
        emailjs.send('process.env.EMAIL_JS_SERVICE_ID', 'template_tbj1etx', templateParams)
            .then((response) => {
            console.log('Email sent!', response.status, response.text);
            }, (error) => {
            console.error('Failed to send email:', error);
            });
    };
    
    const save = () => {
        console.log(users);
    }

    const isDirty = () => {
        if (JSON.stringify(users) !== JSON.stringify(dbData)) { 
            return true; 
        } else {
            return false;
        }
    }

    return (
        <div>
            <Loader config={loader}></Loader>
            <PictureChooser config={pictureChooserConfig} onHide={closePictureChooser}></PictureChooser>

            <div className='flex justify-content-end' style={{marginInline: '1em'}}>
                <Button variant="primary" onClick={save} disabled={!isDirty()}>Save <SaveIcon fontSize='small' /></Button>
            </div>
            
            <div className="overflow-auto padding-1">
                <table className="table">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Avatar</th>
                            <th col-min-width="long-text">First Name</th>
                            <th col-min-width="long-text">Last Name</th>
                            <th col-min-width="long-text">Display Name</th>
                            <th col-min-width="long-text">Email</th>
                            <th>Admin</th>
                            <th>Active</th>
                            <th>Invite</th>
                        </tr>
                    </thead>
                    <tbody>
                        { users.length > 0 && (users.map((u, index) => (          
                        <tr>
                            <td>{u.id}</td>
                            <td>
                                <Button variant="icon" onClick={() => openPictureChooser(index)}>
                                    
                                    <UserChip variant='avatar' user={u} className='header-chip-avatar'></UserChip>
                                    {/* <img className='avatar' src={u.avatarUrl} alt="?" /> */}
                                </Button>
                            </td>
                            <td>
                                <input name="First Name" 
                                    type="fname" 
                                    value={u.firstName}
                                    onChange={(e) => handleUserChange(e.target.name, e.target.value, index)} />
                            </td>
                            <td>
                                <input name="Last Name" 
                                    type="lname" 
                                    value={u.lastName}
                                    onChange={(e) => handleUserChange(e.target.name, e.target.value, index)} />
                            </td>
                            <td>
                                <input name="Display Name" 
                                    type="dname" 
                                    value={u.displayName}
                                    onChange={(e) => handleUserChange(e.target.name, e.target.value, index)} />
                            </td>
                            <td>
                                <input name="Email" 
                                    type="email" 
                                    value={u.email}
                                    onChange={(e) => handleUserChange(e.target.name, e.target.value, index)} />
                            </td>
                            <td>
                                <input name="Admin" 
                                    type="checkbox" 
                                    checked={u.isAdmin}                                
                                    onChange={(e) => handleUserChange(e.target.name, e.target.checked, index)} />
                            </td>
                            <td>
                                <input name="Active" 
                                    type="checkbox" 
                                    checked={u.active}
                                    onChange={(e) => handleUserChange(e.target.name, e.target.checked, index)} />
                            </td>
                            <td><Button variant="outlined" onClick={() => handleInviteClick()}><EmailIcon></EmailIcon></Button></td>
                        </tr>
                        )))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default UsersTab;