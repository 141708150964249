import React from 'react';
import Linkify from 'react-linkify';
import Button from '../../atoms/Button/Button';
import Modal from '../../atoms/Modal/Modal';


function Tooltip({config, onHide}) {    
    return (
        <Modal show={config.show} onHide={onHide} closeOnOverlayClick={true}>
          <h2>{config.header}</h2>
          <p>
            <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                <a target="blank" href={decoratedHref} key={key}>{decoratedText}</a>
            )}>
              {config.body}
            </Linkify></p>

          <div className="modal-footer">
            <Button variant="secondary" onClick={onHide}>Close</Button>
          </div>
      </Modal>

     );    
}

export default Tooltip;