import React, { useReducer, useState, useEffect } from 'react';

import Loader from '../../../../components/Loader/Loader';
import UserChip from '../../../../components/UserChip/UserChip';
import Driver from '../../../../components/Driver/Driver';

import { DropdownButton } from '../../../../atoms/DropdownButton/DropdownButton';
import Button from '../../../../atoms/Button/Button';
import Modal from '../../../../atoms/Modal/Modal';

import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import ReplayIcon from '@mui/icons-material/Replay';

import './DriverPicks.css';


function DriverPicks({questionId, submissionId, disabled}) {
	const [groupAPick, setGroupAPick] = useState();
	const [groupBPick, setGroupBPick] = useState();
	const [groupCPick, setGroupCPick] = useState();
	
	const [groupASelectable, setGroupASelectable] = useState();
	const [groupBSelectable, setGroupBSelectable] = useState();
	const [groupCSelectable, setGroupCSelectable] = useState();
	
	const [showGroupASelectable, setShowGroupASelectable] = useState(false);
	const [showGroupBSelectable, setShowGroupBSelectable] = useState(false);
	const [showGroupCSelectable, setShowGroupCSelectable] = useState(false);
	
	useEffect(() => {
		fetchSubmissionPicks();
		fetchSelectableAnswers();
	}, [questionId, submissionId]);

	
	const fetchSubmissionPicks = () => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
				questionId: questionId,
				submissionId: submissionId
            })
        };
    
		fetch(apiUrl + `/app/submissionPicks`, requestOptions)
			.then(response => response.json())
			.then(data => {
				let pickA = data.filter(pick => pick.order === 1)[0];
				if(pickA === undefined) {
					setGroupAPick({})
				} else {
					setGroupAPick(pickA);
				}
				let pickB = data.filter(pick => pick.order === 2)[0];
				if(pickB === undefined) {
					setGroupBPick({})
				} else {
					setGroupBPick(pickB);
				}
				let pickC = data.filter(pick => pick.order === 3)[0];
				if(pickC === undefined) {
					setGroupCPick({})
				} else {
					setGroupCPick(pickC);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	}

	
	const fetchSelectableAnswers = () => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
				questionId: questionId,
				submissionId: submissionId
            })
        };
    
		fetch(apiUrl + `/app/form/selectableAnswers`, requestOptions)
			.then(response => response.json())
			.then(data => {
				setGroupASelectable(data.filter(answer => answer.order <= 5));
				setGroupBSelectable(data.filter(answer => answer.order > 5 && answer.order <=10));
				setGroupCSelectable(data.filter(answer => answer.order > 10));
			})
			.catch((err) => {
				console.log(err);
			});
	}

	const toggleSelectable = (group) => {		
		if(group === 'A') {
			setShowGroupASelectable(!showGroupASelectable);
		} else if(group === 'B') {
			setShowGroupBSelectable(!showGroupBSelectable);
		} else if(group === 'C') {
			setShowGroupCSelectable(!showGroupCSelectable);
		}
	}


	
    const handleUserAnswerChange = async (group, driver) => {
		console.log(`handleUserAnswerChange (${group})`, driver);
		
		let order;
		if(group === 'A') {
			order = 1;
		} else if(group === 'B') {
			order = 2;
		} else if(group === 'C') {
			order = 3;
		}

		let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				submissionId: submissionId, 
				questionId: questionId,
				answerId: driver.answerId,
				order: order,
				replace: true
			})
		};

		try {
			const response = await fetch(apiUrl + "/app/submission/answer/set", requestOptions);
			const data = await response.json();
			if(data.length > 0) {
				if(group === 'A') {
					setGroupAPick(driver);
				} else if(group === 'B') {
					setGroupBPick(driver);
				} else if(group === 'C') {
					setGroupCPick(driver);
				}
				toggleSelectable(group);
			}
		} catch(err) {
			console.log(err)
		}
    }

    return (<>
		<div className='flex justify-content-between'>
			<h3>Group A</h3>
		</div>
		<div className='selection-container'>			
			<Driver driver={groupAPick} variant='input-toggle'
				group='A'
				inputClick={toggleSelectable}
				disabled={disabled}></Driver>

			{showGroupASelectable === true && groupASelectable.map(driver => (
			<Driver driver={driver} variant='input-pick'
				group='A'
				inputClick={handleUserAnswerChange}
				disabled={disabled}></Driver>
			))}
		</div>
		
		<div className='flex justify-content-between'>
			<h3>Group B</h3>
		</div>
		<div className='selection-container'>
			<Driver driver={groupBPick} variant='input-toggle'
				group='B'
				inputClick={toggleSelectable}
				disabled={disabled}></Driver>

			{showGroupBSelectable === true && groupBSelectable.map(driver => (
			<Driver driver={driver} variant='input-pick'
				group='B'
				inputClick={handleUserAnswerChange}
				disabled={disabled}></Driver>
			))}
		</div>
      
		<div className='flex justify-content-between'>
			<h3>Group C</h3>
		</div>
		<div className='selection-container'>
			<Driver driver={groupCPick} variant='input-toggle'
				group='C'
				inputClick={toggleSelectable}
				disabled={disabled}></Driver>

			{showGroupCSelectable === true && groupCSelectable.map(driver => (
			<Driver driver={driver} variant='input-pick'
				group='C'
				inputClick={handleUserAnswerChange}
				disabled={disabled}></Driver>
			))}
		</div>
    </>);
    
}

export default DriverPicks;