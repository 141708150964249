import React, { useState, useEffect } from 'react';
import UserChip from '../UserChip/UserChip';
import DriverStats from '../DriverStats/DriverStats';
import SkeletonAnswer from '../Skeleton/SkeletonAnswer';
import Button from '../../atoms/Button/Button';
import Chip from '../../atoms/Chip/Chip';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';

import './Driver.css';

/**
 * driver===undefined : skeleton state
 * driver==={} : empty state
 * driver===driver : driver state
 */
const Driver = ({ driver, variant, group, inputClick, disabled, responsesClick }) => {
    const [driverStatsConfig, setDriverStatsConfig] = useState({show: false});  

    const handleInputClick = () => {
        inputClick(group, driver);
    }

    const openDriverStats = () => {
        console.log('openDriverStats', driver);
        setDriverStatsConfig({
            show: true, 
            driver: driver
        });
    }
    const hideDriverStats = () => {
        setDriverStatsConfig({show: false});
    }
    
      
  return (<>
        <DriverStats config={driverStatsConfig} onHide={hideDriverStats}></DriverStats>

        {driver===undefined ? (
        <SkeletonAnswer variant='driver' input={true}></SkeletonAnswer>
        ) : (
        <div variant={variant} className={variant === undefined ? `driver-container` : `dark-card driver-container`}>
            <div className="main">
                {/* <div className='number'>
                    <h3>{driver.status !== null ? driver.status : driver.startPosition}</h3>
                </div> */}

                <div className="driver-details driver-answer">

                    {variant==='input-toggle' && (
                    <div className='input'>
                        {Object.keys(driver).length !== 0 && (
                        <Button variant='icon-inline' onClick={handleInputClick} disabled={disabled}><ChangeCircleIcon fontSize='small' /></Button>
                        )}
                            
                        {Object.keys(driver).length === 0 && (
                        <Button variant='primary' onClick={handleInputClick} disabled={disabled}>Pick</Button>
                        )}                  
                    </div>
                    )}

                    {variant==='input-pick' && (
                    <div className='input'>
                        <Button variant='primary' onClick={handleInputClick} disabled={disabled}>Pick</Button>
                    </div>
                    )}

                    {Object.keys(driver).length !== 0 && (<>
                    <div className='flex gap-05'>
                        <div>
                            <img className="answer-image small" src={driver.profilePicUrl} alt="" />
                            <img className='tiny-overlapping-logo' src={driver.constructorLogoUrl} alt={driver.constructorName} />
                        </div>
                        <div className='flex'>
                            <div className='text-align-left single-line'><b>{driver.firstName} {driver.lastName}</b></div>
                            <Button variant='icon-inline' onClick={openDriverStats}>
                                <InsertChartOutlinedIcon fontSize='small' />
                            </Button>
                        </div>
                    </div>
                    </>)}
                    
                    {Object.keys(driver).length === 0 && (
                    <div>Select Driver</div>
                    )}
                </div>
                
                {Object.keys(driver).length !== 0 && variant !== undefined && (<>
                <div className='driver-points'>
                    <Chip>{driver.points}</Chip>
                    <div className='projected-stats'>Projected: {driver.currentStats?.projectedPoints}</div>
                </div>
                </>)}
                

            </div>
            
                    
            {variant==='responses' && (<>
            <div className="submission-avatars" onClick={(e) => responsesClick(e, driver.submissions)}>
                {driver.submissions.map(user => (
                <UserChip variant='avatar' user={user}></UserChip>
                ))}
            </div>
            </>)}

        </div>
        )}
    </>);
}

export default Driver;
