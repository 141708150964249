import React, { useReducer, useState, useEffect } from 'react';

import Loader from '../../../../components/Loader/Loader';
import UserChip from '../../../../components/UserChip/UserChip';
import Constructor from '../../../../components/Constructor/Constructor';

import { DropdownButton } from '../../../../atoms/DropdownButton/DropdownButton';
import Button from '../../../../atoms/Button/Button';
import Modal from '../../../../atoms/Modal/Modal';

import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import ReplayIcon from '@mui/icons-material/Replay';

import './ConstructorResponses.css';


function ConstructorResponses({questionId, eventId, disabled}) {
	const [constructorPicks, setConstructorPicks] = useState([]);
	
	const [answerSubmissions, setAnswerSubmissions] = useState({show: false, submissions: []});

	useEffect(() => {
		fetchResponses();
	}, [questionId, eventId]);

	
	const fetchResponses = () => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
				questionId: questionId,
				eventId: eventId
            })
        };
    
		fetch(apiUrl + `/app/form/questionResponses`, requestOptions)
			.then(response => response.json())
			.then(data => {
				setConstructorPicks(data);
			})
			.catch((err) => {
				console.log(err);
			});
	}

	const showAnswerSubmissions = (e, submissions) => {
		setAnswerSubmissions({show: true, submissions: submissions});
		console.log(`showAnswerSubmissions`);
		console.log(submissions);
	}
	const closeAnswerSubmissions = () => {
		setAnswerSubmissions({show: false, submissions: []});
	}

    return (<>
        <Modal show={answerSubmissions.show} onHide={closeAnswerSubmissions} center="true" closeOnOverlayClick="true">
            <h2>Picks</h2>
            
            <div className="answer-responses-container">
                {answerSubmissions.submissions.map(user => (
                  <UserChip user={user}></UserChip>
                ))}
            </div>

            
            <div className="modal-footer">
                <Button variant="secondary" onClick={closeAnswerSubmissions}>Close</Button>
            </div>
        </Modal>

		<div className='selection-container'>
			{constructorPicks.map(constructor => (
			<Constructor constructor={constructor} variant='responses'
				responsesClick={showAnswerSubmissions}
				disabled={disabled}></Constructor>
			))}
		</div>
    </>);
    
}

export default ConstructorResponses;