import React, { useEffect, useState } from 'react';

import Loader from '../../../components/Loader/Loader';
import Driver from '../../../components/Driver/Driver';
import Constructor from '../../../components/Constructor/Constructor';

import DriverSettings from './DriverSettings/DriverSettings';
import ConstructorSettings from './ConstructorSettings/ConstructorSettings';

import { DropdownButton } from '../../../atoms/DropdownButton/DropdownButton';
import Button from '../../../atoms/Button/Button';
import Modal from '../../../atoms/Modal/Modal';

import SettingsIcon from '@mui/icons-material/Settings';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import ArticleIcon from '@mui/icons-material/Article';

import './StatsTab.css';

function StatsTab({user, event, schedule}) {
    const [loader, setLoader] = useState({show: false, text: ''});
    
    const [sessionList, setSessionList] = useState(['']);
    const [selectedSession, setSelectedSession] = useState('');

    const [driverSettingsConfig, setDriverSettingsConfig] = useState({show: false, eventId: 0});  
    const [constructorSettingsConfig, setConstructorSettingsConfig] = useState({show: false, eventId: 0});  
    // const [pitStopSettingsConfig, setPitStopSettingsConfig] = useState({show: false, eventId: 0});  

    const [drivers, setDrivers] = useState([]);
    const [eventConstructors, setEventConstructors] = useState([]);

    useEffect(() => {
      if(selectedSession !== '') {
        fetchEventGridData();
      }
    }, [event, selectedSession]);

    useEffect(() => {
		if (schedule.length > 0) {
			console.log('schedule', schedule);
			console.log('event', event);
			setSessionList(schedule.map(s => s.type));
			setSelectedSession(schedule[0].type);
		}
    }, [schedule]);

    const fetchEventGridData = () => {
        let session = schedule.find(s => s.type===selectedSession);
        console.log(session);
        fetchEventDriversData(session);
        fetchEventConstructorsData(session);
    }

    const fetchEventDriversData = (session) => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            eventId: session.id
          })
      };
    
      setLoader({show: true, text: 'Loading Event Drivers...'});
      fetch(apiUrl + `/app/ref/eventDrivers`, requestOptions)
        .then(response => response.json())
        .then(data => {
            let revisedData = data.map(driver => ({
              ...driver,
              posChange: formatNumber(driver.startPosition - driver.currentPosition),
            }));
            setDrivers(revisedData);
            setLoader({show: false});
        })
        .catch((err) => {
            console.log(err);
            setDrivers([]);
            setLoader({show: false});
        });
    }
    function formatNumber(num) {
       if (num > 0) { 
        return `+${num}`; } 
      else if (num < 0) { 
        return `${num}`; 
      } else { 
        return '—'; 
      } 
    }
    
    const fetchEventConstructorsData = (session) => {
      let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
  
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          eventId: session.id
        })
      };
  
      setLoader({show: true, text: 'Loading Event Constructors...'});
      fetch(apiUrl + `/app/ref/eventConstructors`, requestOptions)
        .then(response => response.json())
        .then(data => {
            setEventConstructors(data);
            setLoader({show: false});
        })
        .catch((err) => {
            console.log(err);
            setEventConstructors([]);
            setLoader({show: false});
        });
    }
  
  
  
    const updateSessionData = () => {
      let session = schedule.find(s => s.type===selectedSession);

      let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
  
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          eventId: session.id,
          sessionKey: session.sessionKey
        })
      };
  
      setLoader({show: true, text: 'Updating Session Data from OpenF1...'});
      fetch(apiUrl + `/import/updateSessionData`, requestOptions)
        .then(() => {          
            fetchEventGridData();
            setLoader({show: false});
        })
        .catch((err) => {
            console.log(err);
            setLoader({show: false});
        });
    }
    
    
    const openDriverSettings = () => {
      let session = schedule.find(s => s.type===selectedSession);
      console.log('openDriverSettings', session)
        setDriverSettingsConfig({
            show: true, 
            eventId: session.id,
            type: session.type,
            name: session.name
        });
    }
    const hideDriverSettings = () => {
      	setDriverSettingsConfig({show: false, eventId: 0});
        fetchEventGridData();
    } 
    
    
    const openConstructorSettings = () => {
		let session = schedule.find(s => s.type===selectedSession);
		console.log('openConstructorSettings', session)
        setConstructorSettingsConfig({
            show: true, 
            eventId: session.id,
            type: session.type,
            name: session.name
        });
    }
    const hideConstructorSettings = () => {
      	setConstructorSettingsConfig({show: false, eventId: 0});
        fetchEventGridData();
    }  
    






    
    const getDriverTime = (bestLapTime, gap) => {
      if(gap===0) {
        return formatDuration(bestLapTime)
      } else {
        return `+${formatDuration(gap)}`
      }
    }

    const formatDuration = (duration) => {        
        let minutes = Math.floor(duration / 60);
        let seconds = (duration % 60).toFixed(3); // Keep the thousandth place

        if (minutes === 0) {
            return `${seconds}`;

        } else {
            let [real, decimal] = seconds.split('.');
            real = real.padStart(2, '0'); // Ensure the real part is two digits
            seconds = `${real}.${decimal}`;
            return `${minutes}:${seconds}`;
        }
    }

    return (
    <div className='event-details'>
        <Loader config={loader}></Loader>

        <DriverSettings config={driverSettingsConfig} onHide={hideDriverSettings}></DriverSettings>
        <ConstructorSettings config={constructorSettingsConfig} onHide={hideConstructorSettings}></ConstructorSettings>

        <div className='flex justify-content-between'>
          <DropdownButton 
                options={sessionList}
                value={selectedSession}
                clickFunction={(value) => setSelectedSession(value)}
              >
          </DropdownButton>
          {user.isAdmin && (
          <Button variant='icon' onClick={updateSessionData}>
              <CloudDownloadIcon fontSize="small" />
          </Button>
          )}
        </div>

        <div className="dark-card">
            <div className='flex justify-content-between'>
                <h2>Drivers</h2>

                {user.isAdmin && (
                <Button variant='icon' onClick={openDriverSettings}><SettingsIcon fontSize="small" /></Button>
                )}
            </div>

            <div className='overflow-auto padding-0'>
              <table className="table stats-table">
                <thead>
                    <tr>
                        <th col-width='short-number'>Pos.</th>
                        <th className='subject-cell'></th>
                        <th col-width='long-number' className='text-align-left'>Time</th>
                        <th col-width='short-number' className='text-align-left'>Laps</th>
                    </tr>
                </thead>
                {drivers.length > 0 && (
                <tbody>
                    {drivers.map(d => (
                    <tr>
                        <td>
                          {d.status === null && (
                            <div className='single-line flex gap-05'>
                              <b>{d.currentPosition}</b>
                              <span style={d.posChange==='—' ? {color: 'gray'}: (d.posChange>0 ? {color: 'green'} : {color: 'red'})}>{d.posChange}</span>
                            </div>)}
                          {d.status !== null && (<b>{d.status}</b> )}
                        </td>
                        <td className='subject-cell'>
                            <Driver driver={d}></Driver>
                        </td>
                        <td className='stat-cell'>{getDriverTime(d.bestLapTime, d.gap)}</td>
                        <td className='stat-cell'>{d.lapsCompleted}</td>
                    </tr>
                    ))}
                </tbody>
                )}
            </table>
          </div>
        </div>


        <div className="dark-card">
          <div className='flex justify-content-between'>
              <h2>Constructors</h2>

				{user.isAdmin && (
				<Button variant='icon' onClick={openConstructorSettings}><SettingsIcon fontSize="small" /></Button>
				)}
          </div>

          <div className='overflow-auto padding-0'>
            <table className="table stats-table">
              <thead>
                  <tr>
                      <th className='subject-cell'></th>
                      <th col-width='short-number' className='text-align-left'>Pit Stops</th>
                      <th col-width='short-number' className='text-align-left'>Best</th>
                  </tr>
              </thead>
              {eventConstructors.length > 0 && (
              <tbody>
                  {eventConstructors.map(c => (
                  <tr>
                      <td className='subject-cell'>
							<Constructor constructor={c}></Constructor>
                      </td>

                      <td className='stat-cell'>{c.pitStopCount}</td>
                      <td className='stat-cell'>{`${c.bestPitStop?.toFixed(1)}s`}</td>
                  </tr>
                  ))}
              </tbody>
              )}
            </table>
          </div>
        </div>
    </div>
    );
}

export default StatsTab;