import React, { useState, useEffect } from 'react';
import UserChip from '../UserChip/UserChip';
import ConstructorStats from '../ConstructorStats/ConstructorStats';
import SkeletonAnswer from '../Skeleton/SkeletonAnswer';
import Button from '../../atoms/Button/Button';
import Chip from '../../atoms/Chip/Chip';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';

import './Constructor.css';

/**
 * constructor===undefined : skeleton state
 * constructor==={} : empty state
 * constructor===constructor : constructor state
 */
const Constructor = ({ constructor, variant, inputClick, disabled, responsesClick }) => {
    const [constructorStatsConfig, setConstructorStatsConfig] = useState({show: false});  

    const handleInputClick = () => {
        inputClick(constructor);
    }

    const openConstructorStats = () => {
        console.log('openConstructorStats', constructor);
        setConstructorStatsConfig({
            show: true, 
            constructor: constructor
        });
    }
    const hideConstructorStats = () => {
        setConstructorStatsConfig({show: false});
    }
    
      
  return (<>
        <ConstructorStats config={constructorStatsConfig} onHide={hideConstructorStats}></ConstructorStats>

        {constructor===undefined ? (
        <SkeletonAnswer variant='constructor' input={true}></SkeletonAnswer>
        ) : (    
        <div variant={variant} className={variant === undefined ? `constructor-container` : `dark-card constructor-container`}>
            <div className="main">
                {/* <div className='number'>
                    <h3>{driver.status !== null ? driver.status : driver.startPosition}</h3>
                </div> */}

                <div className="constructor-details constructor-answer">

                    {variant==='input-toggle' && (
                    <div className='input'>
                        {Object.keys(constructor).length !== 0 && (
                        <Button variant='icon-inline' onClick={handleInputClick} disabled={disabled}><ChangeCircleIcon fontSize='small' /></Button>
                        )}
                            
                        {Object.keys(constructor).length === 0 && (
                        <Button variant='primary' onClick={handleInputClick} disabled={disabled}>Pick</Button>
                        )}                  
                    </div>
                    )}

                    {variant==='input-pick' && (
                    <div className='input'>
                        <Button variant='primary' onClick={handleInputClick} disabled={disabled}>Pick</Button>
                    </div>
                    )}

                    {Object.keys(constructor).length !== 0 && (<>
                    <div className='flex gap-05'>
                        <div>
                            <img className="answer-image small" src={constructor.logoUrl} alt={constructor.name} />
                            {constructor.driver !== null && constructor.driver !== undefined && constructor.driver.map((d) => (
                            <img className='tiny-overlapping-logo' src={d.profilePicUrl} alt={d.code} />
                            ))}
                        </div>
                        <div className='flex'>
                            <div className='text-align-left single-line'><b>{`${constructor.name}`}</b></div>
                            <Button variant='icon-inline' onClick={openConstructorStats}>
                                <InsertChartOutlinedIcon fontSize='small' />
                            </Button>
                        </div>
                    </div>
                    </>)}
                    
                    {Object.keys(constructor).length === 0 && (
                    <div>Select Constructor</div>
                    )}
                </div>
                
                {Object.keys(constructor).length !== 0 && variant !== undefined && (<>
                <div className='constructor-points'>
                    <Chip>{constructor.points}</Chip>
                    <div className='projected-stats'>Projected: {constructor.currentStats?.projectedPoints}</div>
                </div>
                </>)}
                

            </div>
            
                    
            {variant==='responses' && (<>
            <div className="submission-avatars" onClick={(e) => responsesClick(e, constructor.submissions)}>
                {constructor.submissions.map(user => (
                <UserChip variant='avatar' user={user}></UserChip>
                ))}
            </div>
            </>)}

        </div>
        )}
    </>);
}

export default Constructor;
