import React, { useState, useEffect, useReducer } from 'react';

import Button from '../../../../atoms/Button/Button';
import Modal from '../../../../atoms/Modal/Modal';

import Loader from '../../../../components/Loader/Loader';
import AreYouSureModal from '../../../../components/AreYouSureModal/AreYouSureModal';
import PictureChooser from '../../../../components/PictureChooser/PictureChooser';
import Driver from '../../../../components/Driver/Driver';
import Constructor from '../../../../components/Constructor/Constructor';

import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import ReplayIcon from '@mui/icons-material/Replay';
import AddIcon from '@mui/icons-material/Add';

import './QuestionSettings.css';


function questionReducer(state, action) {
    console.log(`questionReducer (${action.type}, ${action?.payload})`);   
    
    let newState = structuredClone(state);

    try {  
        switch(action.type) {
            case 'initialize':
                newState = action.payload;
                return newState;
  
            case 'update':
                if(action.payload.property === 'Section Title') {
                    newState.section.title = action.payload.value;
                    
                } else if(action.payload.property === 'Section Description') {
                    newState.section.description = action.payload.value;
                    
                } else if(action.payload.property === 'Question Text') {
                    newState.question.text = action.payload.value;
                    
                } else if(action.payload.property === 'Question Description') {
                    newState.question.description = action.payload.value;

                } else {
                    console.log(`Property ${action.payload.property} not known.`);
                }
                return newState;

            default:
                console.log(`Action ${action.type} not known.`);
                return newState;
        }
    }
    catch (error) {
        console.error(`An error occurred (${action.type}):`, error);
        return newState;
    }
}


function answerReducer(state, action) {
    console.log(`answerReducer (${action.type}, ${action?.payload})`);   
    
    let newState = structuredClone(state);

    let i = action?.payload?.index;
    try {  
        switch(action.type) {
            case 'initialize':
                newState = action.payload;
                return newState;
  

            case 'add':
                newState.push({
                    id: null,
                    text: '',
                    type: 'Selectable',
                    imageUrl: '',                    
                    extRefUrl: null,
                    order: newState.length+1,
                    isCorrect: null,
                    action: 'add',
                });
                
                return newState;
            
            case 'update':
                if(action.payload.property === 'Answer Text') {
                    newState[i].text = action.payload.value;
                    
                } else if(action.payload.property === 'Answer Image URL') {
                    newState[i].imageUrl = action.payload.value;
                    
                } else if(action.payload.property === 'Order') {
                    newState[i].order = action.payload.value;
                    
                } else if(action.payload.property === 'Is Correct') {
                    if(action.payload.value) {
                        newState[i].isCorrect = 1;
                    } else {
                        newState[i].isCorrect = 0;
                    }
                    
                } else {
                    console.log(`Property ${action.payload.property} not known.`);
                }
                return newState;

            case 'delete':
                if(newState[i].action === 'add') {
                    newState.splice(i, 1);
                } else {
                    newState[i].action = 'delete';
                }
                return newState;

            case 'restore':
                newState[i].action = 'none';
                return newState;

            default:
                console.log(`Action ${action.type} not known.`);
                return newState;
        }
    }
    catch (error) {
        console.error(`An error occurred (${action.type}):`, error);
        return newState;
    }
}

function QuestionSettings({config, onHide}) {
    const [loader, setLoader] = useState({show: false, text: ''});
    const [question, dispatchQuestion] = useReducer(questionReducer, []);
    const [dbQuestionData, setDbQuestionData] = useState();
    const [answer, dispatchAnswer] = useReducer(answerReducer, []);
    const [dbAnswerData, setDbAnswerData] = useState();
    const [deleteModalConfig, setDeleteModalConfig] = useState({show: false});
    const [hasSaved, setHasSaved] = useState(false);
    const [pictureChooserConfig, setPictureChooserConfig] = useState({show: false});
    
    useEffect(() => {
        if(config.show) {
            fetchQuestion();
            fetchAnswer();
            console.log('config', config);
        }

    }, [config]);

    const fetchQuestion = () => {
        setLoader({show: true, text: 'Fetching question...'});
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                questionId: config.questionId
            })
        };

        fetch(apiUrl + "/app/form/questionInfo", requestOptions)
            .then(response => {
                if(response.status === 200) {
                    return response.json()
                }
            })
            .then(data => {
                dispatchQuestion({type: 'initialize', payload: data});
                setDbQuestionData(data);
                setLoader({show: false});
            })
        .catch((err) => {
            console.log(err);
        });
    }

    const fetchAnswer = () => {
        setLoader({show: true, text: 'Fetching answer...'});
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                questionId: config.questionId,                
                eventId: config.eventId
            })
        };

        fetch(apiUrl + "/app/form/answerInfo", requestOptions)
            .then(response => {
                if(response.status === 200) {
                    return response.json()
                }
            })
            .then(data => {
                data.sort((a, b) => a.order - b.order);
                dispatchAnswer({type: 'initialize', payload: data});
                setDbAnswerData(data);
                setLoader({show: false});
            })
        .catch((err) => {
            console.log(err);
        });
    }



    const saveQuestion = () => {
        // console.log('saveForm', state.form);
        setLoader({show: true, text: 'Saving Question...'});
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(question)
        };

        fetch(apiUrl + "/app/form/question/save", requestOptions)
          .then(response => {
            if(response.status === 200) {
                fetchQuestion();
                setHasSaved(true);
            }
            setLoader({show: false});
          })
        .catch((err) => {
            console.log(err);
        });
    }



    const saveDriverPicksAnswers = () => {
        setLoader({show: true, text: 'Saving Answers...'});
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                questionId: question.question.questionId,
                answer: answer
            })
        };

        fetch(apiUrl + "/app/form/answer/saveDriverPicksAnswers", requestOptions)
          .then(response => {
            if(response.status === 200) {
                fetchAnswer();
                setHasSaved(true);
            }
            setLoader({show: false});
          })
        .catch((err) => {
            console.log(err);
        });
    }



    const saveBonusRadioAnswers = () => {
        setLoader({show: true, text: 'Saving Answers...'});
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                questionId: question.question.questionId,
                answer: answer
            })
        };

        fetch(apiUrl + "/app/form/answer/saveBonusRadioAnswers", requestOptions)
          .then(response => {
            if(response.status === 200) {
                fetchAnswer();
                setHasSaved(true);
            }
            setLoader({show: false});
          })
        .catch((err) => {
            console.log(err);
        });
    }

    const handleQuestionChange = (property, value) => {
        console.log(`handleQuestionChange [${property}] => ${value}`);
        
        dispatchQuestion({type: 'update', payload: {property, value}});
    }

    const deleteQuestion = () => {
        setLoader({show: true, text: 'Deleting question...'});
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
         
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              questionId: config.questionId
            })
        };
        
        fetch(apiUrl + `/app/form/question/delete2`, requestOptions)
          .then(response => response.json())
          .then(() => {    
            setLoader({show: false});
            onHide('Reload');
        })
          .catch((err) => console.log(err));
    }

    const openDeleteModal = () => {
        setDeleteModalConfig({show: true});
    }
    const closeDeleteModal = (action) => {
        setDeleteModalConfig({show: false});

        if(action === 'Confirm') {
            deleteQuestion();
        }
    }

    const handleCloseEvent = () => {
        if(hasSaved) {
            onHide('Reload');
        } else {
            onHide();
        }
        setHasSaved(false);
    }

    const addAnswer = () => {
        dispatchAnswer({type: 'add', payload: {}});
    }
    const handleAnswerChange = (property, value, i) => {
        console.log(`handleAnswerChange [${property}] => ${value} [${i}]`);
        
        dispatchAnswer({type: 'update', payload: {index: i, property: property, value: value}});
    }

    const deleteAnswer = (_, i) => {
        dispatchAnswer({type: 'delete', payload: {index: i}});
    }

    const restoreAnswer = (_, i) => {
        dispatchAnswer({type: 'restore', payload: {index: i}});
    }

    const openPictureChooser = (index) => {
        setPictureChooserConfig({show: true, index: index});
    }

    const closePictureChooser = (url) => {
        console.log(`url (${url.length})`, url);
        
        if(url.length > 0) {
            let index = pictureChooserConfig.index;    
            handleAnswerChange('Answer Image URL', url, index);
        }

        setPictureChooserConfig({show: false});
    }
    
    const isQuestionDirty = () => {
        return JSON.stringify(question) !== JSON.stringify(dbQuestionData);
    }
    
    const isAnswerDirty = () => {
        return JSON.stringify(answer) !== JSON.stringify(dbAnswerData);
    }
    
    return (
        <Modal show={config.show} onHide={handleCloseEvent} closeOnOverlayClick={true} className='full-width'>
            <Loader config={loader}></Loader>
            <AreYouSureModal config={deleteModalConfig} onHide={closeDeleteModal}></AreYouSureModal>
            <PictureChooser config={pictureChooserConfig} onHide={closePictureChooser}></PictureChooser>
            
            <div className='flex flex-column gap-1'>
                <div>
                    <div className="modal-header">
                        <h2 className="margin-0">Question Settings</h2>
                        <Button variant="warn" onClick={openDeleteModal}>Delete<SaveIcon fontSize='small' /></Button>
                        <Button variant="primary" onClick={saveQuestion} disabled={!isQuestionDirty()}>Save <SaveIcon fontSize='small' /></Button>
                    </div>
                    <div className="label-adorner">{`{ sectionId: ${dbQuestionData?.section?.sectionId}, questionId: ${dbQuestionData?.question?.questionId} }`}</div>
                </div>
                

                <div>
                    <h4>Section Title</h4>
                    <input 
                            className="input-section"
                            type="text" 
                            value={question.section?.title} 
                            placeholder="Section Title" 
                            onChange={(e) => handleQuestionChange('Section Title', e.target.value)} />
                </div>

                <div>
                    <h4>Section Description</h4>
                    <textarea 
                        type="text" 
                        value={question.section?.description} 
                        placeholder="Description" 
                        onChange={(e) => handleQuestionChange('Section Description', e.target.value)} />
                </div>

                <div>
                    <h4>Question Text</h4>
                    <input 
                            className="input-question"
                            type="text" 
                            value={question.question?.text} 
                            placeholder="Question" 
                            onChange={(e) => handleQuestionChange('Question Text', e.target.value)} />
                </div>

                <div>
                    <h4>Question Description</h4>
                    <textarea
                        type="text" 
                        value={question.question?.description} 
                        placeholder="Description" 
                        onChange={(e) => handleQuestionChange('Question Description', e.target.value)} />
                </div>

                
                <div className="modal-header">
                    <h4>Answers</h4>
                    {question.question?.type === 'Driver Picks' && (
                    <Button variant="primary" onClick={saveDriverPicksAnswers} disabled={!isAnswerDirty()}>Save <SaveIcon fontSize='small' /></Button>
                    )}
                    {question.question?.type === 'Bonus Radio' && (
                    <Button variant="primary" onClick={saveBonusRadioAnswers} disabled={!isAnswerDirty()}>Save <SaveIcon fontSize='small' /></Button>
                    )}
                </div>
                {question.question?.type === 'Driver Picks' && (
                <table className='table'>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Group</th>
                            <th>Driver</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {answer.map((a, index) => (
                        <tr>
                            <td>{a.answerId}</td>
                            <td>
                                <div className='input-number'>
                                    <input
                                        type="number" 
                                        value={a.order}
                                        onChange={(e) => handleAnswerChange('Order', e.target.value, index)} />
                                    <div>({a.group})</div> 
                                </div>
                            </td>
                            <td>
                                <Driver driver={a}></Driver>
                            </td>
                            <td>
                                {a.action !== 'delete' && (
                                <Button variant="icon" onClick={(e) => deleteAnswer(e, index)}><DeleteIcon fontSize='small' /></Button>
                                )}
                                {a.action === 'delete' && (
                                <Button variant="icon" onClick={(e) => restoreAnswer(e, index)}><ReplayIcon fontSize='small' /></Button>
                                )}
                            </td>
                        </tr>
                        ))}
                    </tbody>
                </table>
                )}
                    

                {question.question?.type === 'Constructor Picks' && (
                <table className='table'>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Constructor</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {answer.map((a, index) => (
                        <tr>
                            <td>{a.answerId}</td>
                            <td>
                                <Constructor constructor={a}></Constructor>
                            </td>
                            <td>
                                {a.action !== 'delete' && (
                                <Button variant="icon" onClick={(e) => deleteAnswer(e, index)}><DeleteIcon fontSize='small' /></Button>
                                )}
                                {a.action === 'delete' && (
                                <Button variant="icon" onClick={(e) => restoreAnswer(e, index)}><ReplayIcon fontSize='small' /></Button>
                                )}
                            </td>
                        </tr>
                        ))}
                    </tbody>
                </table>
                )}
                    

                {question.question?.type === 'Bonus Radio' && (<>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Text</th>
                            <th>Image</th>
                            <th>Correct</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {answer.map((a, index) => (
                        <tr>
                            <td>{a.answerId}</td>
                            <td>
                                <input
                                    type="text" 
                                    value={a.text}
                                    onChange={(e) => handleAnswerChange('Answer Text', e.target.value, index)} />
                            </td>
                            <td>
                                <Button variant="icon" onClick={() => openPictureChooser(index)}>
                                    <img className="answer-image small" src={a.imageUrl} alt="?" />
                                </Button>
                            </td>
                            <td>
                                <input name="Is Correct" 
                                    type="checkbox" 
                                    checked={a.isCorrect === 1}
                                    onChange={(e) => handleAnswerChange(e.target.name, e.target.checked, index)} />
                            </td>
                            <td>
                                {a.action !== 'delete' && (
                                <Button variant="icon" onClick={(e) => deleteAnswer(e, index)}><DeleteIcon fontSize='small' /></Button>
                                )}
                                {a.action === 'delete' && (
                                <Button variant="icon" onClick={(e) => restoreAnswer(e, index)}><ReplayIcon fontSize='small' /></Button>
                                )}
                            </td>
                        </tr>
                        ))}
                    </tbody>
                </table>
            
                <div className="adder" onClick={addAnswer}>
                    <div className="adder-line">
                    <div className="adder-label"><AddIcon fontSize="small" />Add Answer</div>
                    </div>
                </div>
                </>)}

            </div>

            <div className="modal-footer">
                <Button variant="outline-light" onClick={handleCloseEvent}>Cancel</Button>
            </div>

        </Modal>

     );
    
}

export default QuestionSettings;