import React, { useReducer, useState, useEffect } from 'react';

import Loader from '../../../../components/Loader/Loader';
import UserChip from '../../../../components/UserChip/UserChip';
import Answer from '../../../../components/Answer/Answer';

import { DropdownButton } from '../../../../atoms/DropdownButton/DropdownButton';
import Button from '../../../../atoms/Button/Button';
import Modal from '../../../../atoms/Modal/Modal';

import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import ReplayIcon from '@mui/icons-material/Replay';

import './BonusRadioPicks.css';


function BonusRadioPicks({questionId, submissionId, disabled}) {
	const [pick, setPick] = useState();	
	const [selectable, setSelectable] = useState();	
	const [showSelectable, setShowSelectable] = useState(false);
	
	useEffect(() => {
		fetchSubmissionPicks();
		fetchSelectableAnswers();
	}, [questionId, submissionId]);

	
	const fetchSubmissionPicks = () => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
				questionId: questionId,
				submissionId: submissionId
            })
        };
    
		fetch(apiUrl + `/app/submissionPicks`, requestOptions)
			.then(response => response.json())
			.then(data => {
				let userPick = data[0];
				if(userPick === undefined) {
					setPick({})
				} else {
					setPick(userPick);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	}

	
	const fetchSelectableAnswers = () => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
				questionId: questionId,
				submissionId: submissionId
            })
        };
    
		fetch(apiUrl + `/app/form/selectableAnswers`, requestOptions)
			.then(response => response.json())
			.then(data => {
				setSelectable(data);
			})
			.catch((err) => {
				console.log(err);
			});
	}

	const toggleSelectable = () => {
		setShowSelectable(!showSelectable);
	}


    const handleUserAnswerChange = async (answer) => {
		console.log(`handleUserAnswerChange`, answer);

		let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				submissionId: submissionId, 
				questionId: questionId,
				answerId: answer.answerId,
				order: 0,
				replace: true
			})
		};

		try {
			const response = await fetch(apiUrl + "/app/submission/answer/set", requestOptions);
			const data = await response.json();
			if(data.length > 0) {
				setPick(answer);
				toggleSelectable();
			}
		} catch(err) {
			console.log(err)
		}
    }

    return (<>
		<div className='selection-container'>
			{showSelectable === false && (
			<Answer answer={pick} variant='input-toggle'
				inputClick={toggleSelectable}
				disabled={disabled}></Answer>
			)}
			
			{showSelectable === true && selectable.map(answer => (
			<Answer answer={answer} variant='input-pick'
				inputClick={handleUserAnswerChange}
				disabled={disabled}></Answer>
			))}
		</div>
    </>);
    
}

export default BonusRadioPicks;