import React, { useEffect, useState } from 'react';
import { useSearchParams } from "react-router-dom";

import Loader from '../../components/Loader/Loader';

import EventSettings from './EventSettings/EventSettings';
import ScheduleTab from './ScheduleTab/ScheduleTab';
import PicksTab from './PicksTab/PicksTab';
import StatsTab from './StatsTab/StatsTab';
import ResultsTab from './ResultsTab/ResultsTab';
import ResponsesTab from './ResponsesTab/ResponsesTab';

import TabGroup from '../../atoms/Tabs/TabGroup';
import Tab from '../../atoms/Tabs/Tab';
import Button from '../../atoms/Button/Button';

import SettingsIcon from '@mui/icons-material/Settings';

import './Event.css';

function Event({user}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [loader, setLoader] = useState({show: false, text: ''});


  const [event, setEvent] = useState({eventId: searchParams.get('event'), datetime: new Date()});
  const [schedule, setSchedule] = useState([]);
  const [circuit, setCircuit] = useState({});
  const [eventSettingsConfig, setEventSettingsConfig] = useState({show: false, eventId: 0});  

  
  const [activeTab, setActiveTab] = useState('Schedule');
  const handleTabChange = (newTab) => {
      setActiveTab(newTab);
      
      // Add the new query param value to the queryString
      searchParams.set("tab", newTab);
    
      // Enqueue navigation action that updates the queryString
      setSearchParams(searchParams);
  }


  
  const fetchEventData = () => {
    let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        eventId: searchParams.get('event'),
        userId: user.userId
      })
    };

    setLoader({show: true, text: 'Loading Event...'});
    fetch(apiUrl + `/app/event`, requestOptions)
      .then(response => response.json())
      .then(data => {
          data.event.datetime = new Date(data.event.date);
          
          data.schedule.forEach(d => d.datetime = new Date(d.date))
          setEvent(data.event);
          setCircuit(data.circuit);
          setSchedule(data.schedule.sort((a, b) => b.datetime - a.datetime));

          setLoader({show: false});
      })
      .catch((err) => {
          console.log(err);
          setLoader({show: false});
      });
  }
  

  useEffect(() => {
    fetchEventData();
    
    if(searchParams.get('tab') !== undefined) {
      let validTabs = [
        'Schedule',
        'Stats',
        'Picks',
        'Results',
        'Points'
      ];

      if(validTabs.includes(searchParams.get('tab'))) {
        setActiveTab(searchParams.get('tab'));
      }
    }
  }, [user]);

  const openEventSettings = () => {
    setEventSettingsConfig({
        show: true, 
        event
    });
}
const hideEventSettings = () => {
    setEventSettingsConfig({show: false, eventId: 0});
    fetchEventData();
}


  return ( 
    <div className="page-container">
      <Loader config={loader}></Loader>
      <EventSettings config={eventSettingsConfig} onHide={hideEventSettings}></EventSettings>


      <div className="flex justify-content-center">
        <h2 className='single-line'>{event.name}</h2>

        {user.isAdmin && (
        <Button variant='icon' onClick={openEventSettings}>
            <SettingsIcon fontSize="small" />
        </Button>
        )}
      </div>


      <TabGroup>
          <Tab label='Schedule' activeTab={activeTab} onClick={handleTabChange} />
          <Tab label='Stats' activeTab={activeTab} onClick={handleTabChange} />
          {user.userId !== null && (<Tab label='Picks' activeTab={activeTab} onClick={handleTabChange} />)}
          {user.userId !== null && (<Tab label='Responses' activeTab={activeTab} onClick={handleTabChange} disabled={false} />)}
          {user.userId !== null && (<Tab label='Results' activeTab={activeTab} onClick={handleTabChange} disabled={false} />)}
      </TabGroup>

      <div className='tab-outlet'>
          {activeTab === "Schedule" && (
            <ScheduleTab user={user} event={event} fetchEventData={fetchEventData} circuit={circuit} schedule={schedule}></ScheduleTab>
          )}
          
          {activeTab === "Stats" && (
            <StatsTab user={user} event={event} schedule={schedule}></StatsTab>
          )}
          
          {activeTab === "Picks" && (
            <PicksTab user={user} event={event}></PicksTab>
          )}
          
          {activeTab === "Responses" && (
            <ResponsesTab user={user} event={event}></ResponsesTab>
          )}  
          
          {activeTab === "Results" && (
            <div className="dark-card">
              <ResultsTab user={user} event={event}></ResultsTab>
            </div>
          )}
        </div>
    </div>
  );
}

export default Event;