import React, { useState, useEffect } from 'react';
import Button from '../../atoms/Button/Button';
import Modal from '../../atoms/Modal/Modal';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Chip from '../../atoms/Chip/Chip';
import './DriverStats.css';

const DriverStats = ({config, onHide}) => {
    const [driver, setDriver] = useState({});
    
    useEffect(() => {
        if(config.show) {
            console.log('config', config);
            setDriver(config.driver);
        }

    }, [config]);

  return (
    <Modal show={config.show} onHide={onHide} closeOnOverlayClick={true}>
    {driver.lastStats !== undefined && driver.lastStats !== null && (
    <div className='dark-card gap-05'>
        <div className='flex justify-content-between align-items-center'>
            <h4>Previous Race</h4>
            <Chip>{driver.lastStats.points}</Chip>
        </div>

        <p>
            <span>Laps: {driver.lastStats.lapsCompleted} </span> <br />

            {driver.lastStats.status===null && (<>
                <span>Finished: P{driver.lastStats.endPosition} </span>
                {driver.lastStats.changePosition>0 && (
                    <span style={{color: 'green'}}>(<ArrowDropUpIcon fontSize='small' />{driver.lastStats.changePosition})</span>
                )}
                {driver.lastStats.changePosition===0 && (
                    <span>(-)</span>
                )}
                {driver.lastStats.changePosition<0 && (
                    <span style={{color: 'red'}}>(<ArrowDropDownIcon fontSize='small' />{Math.abs(driver.lastStats.changePosition)})</span>
                )}
            </>)}
            
            {driver.lastStats.status!==null && (
            <div className='flex gap-05 align-items-center'>
                <div>Finished:</div>
                <Chip>{driver.lastStats.status}</Chip>
            </div>
            )}
        </p>

        {(driver.lastStats.fastestLap || driver.lastStats.dotd) && (
        <div className='flex gap-05'>
            {driver.lastStats.fastestLap && (
            <Chip>Fastest Lap</Chip>
            )}
            {driver.lastStats.dotd && (
            <Chip>DotD</Chip>
            )}
        </div>
        )}
    </div>
    )}

    <div className='dark-card'>
        <div className='flex justify-content-between align-items-center'>
            <h4>This Race</h4>
            <Chip>{driver.currentStats?.points}</Chip>
        </div>
        
        <p>
            {driver.currentStats?.endPosition===null && (<>
                <span>Starting: P{driver.currentStats?.startPosition}</span>
            </>)}
            
            {driver.currentStats?.endPosition!==null && (<>
                <span>Laps: {driver.currentStats?.lapsCompleted} </span>  <br />
            
                {driver.currentStats?.status===null && (<>
                    <span>Finished: P{driver.currentStats?.endPosition} </span>
                    {driver.currentStats?.changePosition>0 && (
                        <span style={{color: 'green'}}>(<ArrowDropUpIcon fontSize='small' />{driver.currentStats?.changePosition})</span>
                    )}
                    {driver.currentStats?.changePosition===0 && (
                        <span>(-)</span>
                    )}
                    {driver.currentStats?.changePosition<0 && (
                        <span style={{color: 'red'}}>(<ArrowDropDownIcon fontSize='small' />{Math.abs(driver.currentStats?.changePosition)})</span>
                    )}
                </>)}
                
                {driver.currentStats?.status!==null && (
                <div className='flex gap-05 align-items-center'>
                    <div>Finished:</div>
                    <Chip>{driver.currentStats?.status}</Chip>
                </div>
                )}
            </>)}
        </p>
        
        {(driver.currentStats?.fastestLap || driver.currentStats?.dotd) && (
        <div className='flex gap-05'>
            {driver.currentStats?.fastestLap && (
            <Chip>Fastest Lap</Chip>
            )}
            {driver.currentStats?.dotd && (
            <Chip>DotD</Chip>
            )}
        </div>
        )}
    </div>
  </Modal>

  );
}

export default DriverStats;
