import React, { useState, useEffect } from 'react';
import Linkify from 'react-linkify';

import Loader from '../../../components/Loader/Loader';
import Tooltip from '../../../components/Tooltip/Tooltip';
import SkeletonQuestion from '../../../components/Skeleton/SkeletonQuestion';

import DriverResponses from './DriverResponses/DriverResponses';
import ConstructorResponses from './ConstructorResponses/ConstructorResponses';
import BonusRadioResponses from './BonusRadioResponses/BonusRadioResponses';
import UserChip from '../../../components/UserChip/UserChip';

import Chip from '../../../atoms/Chip/Chip';
import Button from '../../../atoms/Button/Button';
import Modal from '../../../atoms/Modal/Modal';
import SettingsIcon from '@mui/icons-material/Settings';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import './ResponsesTab.css';

function ResponsesTab({user, event}) {
    const [loader, setLoader] = useState({show: false,text: ''});
    const [results, setResults] = useState([]);
	const [tooltipConfig, setTooltipConfig] = useState({show: false});
    



	useEffect(() => {
		console.log('ResponsesTab', event);
		if(user.userId !== undefined && user.userId !== null && event.eventId !== undefined && event.eventId !== null) {
		  fetchPicks();
		}
	}, [user, event]);

	const fetchPicks = (submissionId) => {
		let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
	
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				eventId: event.eventId,
				userId: user.userId,
				submissionId: submissionId
			})
		};
	
		// setLoader({show: true, text: 'Loading Picks...'});
		fetch(apiUrl + `/app/picks`, requestOptions)
			.then(response => response.json())
			.then(data => {
				if(data.form !== undefined) {
					data.form.section.sort((a, b) => a.order - b.order);
				}

				setResults(data);
			})
			.catch((err) => {
				console.log(err);
			});
	}

	// Tooltip
	const showTooltip = (e, header, body) => {
		setTooltipConfig({show: true, header: header, body: body});
	}
	const closeTooltip = () => {
		setTooltipConfig({show: false, header: '', body: ''});
	}
    
	// const [currentOpenAccordian, setCurrentOpenAccordian] = useState(null);
	
	// const openAccordian = (e, a) => {
	// 	e.preventDefault();
	// 	e.stopPropagation();
		
	// 	if(currentOpenAccordian!==a.answerId) {
	// 	console.log('Open Accordian', a.answerId);
	// 	setCurrentOpenAccordian(a.answerId);
	// 	} else {
	// 	setCurrentOpenAccordian(null);
	// 	}
	// }
	// const isOpenAccordian = (answer) => {
	// 	if(currentOpenAccordian===answer.id) {
	// 	console.log('Open Accordian =>', answer.id);
	// 	}
	// 	return currentOpenAccordian===answer.id;
	// }
      
  
  return (
    <div className="responses">
    
        <Loader config={loader}></Loader>
        <Tooltip config={tooltipConfig} onHide={closeTooltip}></Tooltip>

		
		
		{results.length === 0 ? (<SkeletonQuestion></SkeletonQuestion>) : (<> 
		{results.form?.section.length > 0 && results.form.section.map(s => (<>
        <div className="dark-card section margin-inline-auto scrollable" key={`s${s.id}`} id={s.id}>

            { s.title!==undefined && (
            <div className="section-header">
                <h2>{s.title}</h2>

                {s.description?.length > 0 && (
                <Button variant="icon" onClick={(e) => showTooltip(e, s.title, s.description)} ><InfoOutlinedIcon fontSize="small" /></Button>
                )}
            </div>
            )}
            
            { s.question.length > 0 && s.question[0].id !== undefined && (
				s.question.map(q => (<>
				<div className='question-header'>
					<div className="flex justify-content-between align-items-center">
						<h3>{q.text}</h3>
						<div className='flex'>
							{q.description?.length > 0 && (
							<Button variant="icon" onClick={(e) => showTooltip(e, q.text, q.description)} ><InfoOutlinedIcon fontSize="small" /></Button>
							)}

						
							{/* {user.isAdmin && (
							<Button variant='icon' onClick={(e) => openQuestionSettings(e, q.id)}>
								<SettingsIcon fontSize="small" />
							</Button>
							)} */}
						</div>
					</div>
				</div>

                {q.type === 'Driver Picks' && (
				<DriverResponses questionId={q.id} eventId={event.eventId} disabled={false}></DriverResponses>
				)}


                {q.type === 'Constructor Picks' && (
				<ConstructorResponses questionId={q.id} eventId={event.eventId} disabled={false}></ConstructorResponses>
                )}

				{q.type === 'Bonus Radio' && (
				<BonusRadioResponses questionId={q.id} eventId={event.eventId} disabled={false}></BonusRadioResponses>
                )}
				</>))
			)}
		</div>
		</>))}
		</>)}
    </div>
    )
}

export default ResponsesTab;