import React, { useReducer, useEffect, useState } from 'react';

import Loader from '../../../components/Loader/Loader';
import Tooltip from '../../../components/Tooltip/Tooltip';
import SkeletonQuestion from '../../../components/Skeleton/SkeletonQuestion';

import PicksSettings from './PicksSettings/PicksSettings';
import AddQuestion from './AddQuestion/AddQuestion';
import QuestionSettings from './QuestionSettings/QuestionSettings';
import DriverPicks from './DriverPicks/DriverPicks';
import ConstructorPicks from './ConstructorPicks/ConstructorPicks';
import BonusRadioPicks from './BonusRadioPicks/BonusRadioPicks';

import { DropdownButton } from '../../../atoms/DropdownButton/DropdownButton';
import Button from '../../../atoms/Button/Button';

import SettingsIcon from '@mui/icons-material/Settings';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AddIcon from '@mui/icons-material/Add';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import './PicksTab.css';

function reducer(state, action) {
  console.log(`reducer (${action.type}, ${action?.payload})`);   
  
  let newState = structuredClone(state);
  try {

      let i = action?.payload?.index;

      switch(action.type) {
          case 'initialize':
              return action.payload;

          case 'toggle_expand_question_answers':
              newState.form.section.forEach((section, sIndex) => {
                if (section.id === action.payload.sectionId) {
                  section.question.forEach((question, qIndex) => {
                    if (question.id === action.payload.questionId) {
                      let expandedArray = JSON.parse(JSON.stringify(question.expanded));
                      let order = action.payload.order;
                      if (expandedArray[order] === 'true') {
                        expandedArray[order] = 'false';
                      } else {
                        expandedArray[order] = 'true';
                      }
                      newState.form.section[sIndex].question[qIndex].expanded = expandedArray;
                    }
                  });
                }
              });
              return newState;


          case 'set_pick':
            let order = action.payload.order;
            newState.form.section.forEach((section, sIndex) => {
              if (section.id === action.payload.sectionId) {
                section.question.forEach((question, qIndex) => {
                  if (question.id === action.payload.questionId) {
                    question.answer.forEach((answer, aIndex) => {
                      if (answer.id === action.payload.answerId) {
                        // if (event.target.getAttribute('name').startsWith('User Answer Radio')) {
                          newState.form.section[sIndex].question[qIndex].answer.forEach((el)=>{if(el.userAnswer === order) {el.userAnswer = undefined}});

                          
                          newState.form.section[sIndex].question[qIndex].answer[aIndex].userAnswer = order;

                          let expandedArray = JSON.parse(JSON.stringify(question.expanded));
                          
                          if(expandedArray[order] === 'true') {
                            expandedArray[order] = 'false';
                          } else {
                            expandedArray[order] = 'true';
                          }
                          newState.form.section[sIndex].question[qIndex].expanded = expandedArray;
                      }
                    });
                  }
                });
              }
            });
            return newState;

          default:
              console.log(`Action ${action.type} not known.`);
              return newState;
      }
  }
  catch (error) {
      console.error(`An error occurred (${action.type}):`, error);
      return newState;
  }
}


function PicksTab({user, event}) {
    const [loader, setLoader] = useState({show: false, text: ''});

    const [picksSettingsConfig, setPicksSettingsConfig] = useState({show: false});
    const [tooltipConfig, setTooltipConfig] = useState({show: false});
    const [addQuestionModal, setAddQuestionModal] = useState({show: false});
    const [questionSettingsModal, setQuestionSettingsModal] = useState({show: false});

    const [picks, dispatch] = useReducer(reducer, []);


    useEffect(() => {
        console.log('PicksTab', event);
        if(user.userId !== undefined && user.userId !== null && event.eventId !== undefined && event.eventId !== null) {
          fetchPicks();
        }
    }, [user, event]);

    const fetchPicks = (submissionId) => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
				eventId: event.eventId,
				userId: user.userId,
				submissionId: submissionId
            })
        };
    
		// setLoader({show: true, text: 'Loading Picks...'});
		fetch(apiUrl + `/app/picks`, requestOptions)
			.then(response => response.json())
			.then(data => {
				if(data.form !== undefined) {
					data.form.section.sort((a, b) => a.order - b.order);
				}

				dispatch({type: 'initialize', payload: data});

				if(data.form?.formId === undefined) {
					createForm();
				} else if(data.submission?.submissionId === undefined) {
					createSubmission();
				}
			})
			.catch((err) => {
				console.log(err);
			});
    }

    
    const createForm = () => {
      let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;

      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
              eventId: event.eventId
          })
      };

      setLoader({show: true, text: 'Creating Form...'});
      fetch(apiUrl + "/app/form/create", requestOptions)
        .then(response => response.json())
        .then(data => {
          setLoader({show: false});
          createSubmission();
        })
        .catch((err) => {
          console.log(err);
          setLoader({show: false});
        });
    }
      
    const createSubmission = () => {
      let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;

      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            eventId: event.eventId, 
            userId: user.userId 
          })
      };

      setLoader({show: true, text: 'Creating Submission...'});
      fetch(apiUrl + "/app/submission/create", requestOptions)
        .then(response => response.json())
        .then(data => {
          setLoader({show: false});
          fetchPicks();
        })
        .catch((err) => {
          console.log(err);
          setLoader({show: false});
        });
    }

    // Add Question Modal
    const openAddQuestionModal = (afterSection) => {
      setAddQuestionModal({show: true, userId: user.userId, formId: picks.form?.formId, afterSection: afterSection});
    }
    const closeAddQuestionModal = (action) => {
      console.log('closeAddQuestionModal', action);
      if(action === 'Reload') {
        fetchPicks();
      }
      setAddQuestionModal({show: false});
    }

    // Question Settings Modal
    const openQuestionSettings = (e, questionId) => {
      setQuestionSettingsModal({show: true, questionId: questionId, eventId: event.eventId});
    }
    const closeQuestionSettings = (action) => {
      if(action === 'Reload') {
        fetchPicks();
      }
      setQuestionSettingsModal({show: false});
    }

    // const toggleExpandQuestionAnswers = (e, sectionId, questionId, order) => {
    //   dispatch({type: 'toggle_expand_question_answers', payload: {sectionId: sectionId, questionId: questionId, order: order}});
    // }

    // const handleUserAnswerChange = (event, sectionId, questionId, answerId, order) => {
    //   console.log(`handleUserAnswerChange`);
    //   // if(form.state==='Complete') return
    //   if(picks.submission.state==='Submitted') return


    //   let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    //   const requestOptions = {
    //       method: 'POST',
    //       headers: { 'Content-Type': 'application/json' },
    //       body: JSON.stringify({
    //         submissionId: picks.submission.submissionId, 
    //         questionId: questionId,
    //         answerId: answerId,
    //         order: order,
    //         replace: true
    //       })
    //   };

    //   fetch(apiUrl + "/app/submission/answer/set", requestOptions)
    //     .then(response => response.json())
    //     .then(() => {
    //       dispatch({type: 'set_pick', payload: {sectionId, questionId, answerId, order}});
          
    //   })
    //     .catch((err) => console.log(err));
    // }

    // // Answer Accordian
    // const openAccordian = (e, a) => {
    //   e.preventDefault();
    //   e.stopPropagation();
      
    //   if(currentOpenAccordian!==a.answerId) {
    //     console.log('Open Accordian', a.answerId);
    //     setCurrentOpenAccordian(a.answerId);
    //   } else {
    //     setCurrentOpenAccordian(null);
    //   }
    // }
    // const isOpenAccordian = (answer) => {
    //   if(currentOpenAccordian===answer.id) {
    //     console.log('Open Accordian =>', answer.id);
    //   }
    //   return currentOpenAccordian===answer.id;
    // }

    // Picks Settings
    const openPicksSettings = () => {
      console.log('openFormSettings');

      let form = {
        formId: picks.form.formId,
        state: picks.form.state,
        memeUrl: picks.form.memeUrl,
        submissionDeadline: picks.form.submissionDeadline
      }

      let submission = {
        submissionId: picks.submission.submissionId,
        state: picks.submission.state
      }

      setPicksSettingsConfig({
          show: true, 
          form: form,
          submission: submission
      });
    }
    const hidePicksSettings = (action, value) => {
      setPicksSettingsConfig({show: false});
      if(action === 'Reload') {
        fetchPicks(value);
      }
    }

    // Tooltip
    const showTooltip = (e, header, body) => {
      setTooltipConfig({show: true, header: header, body: body});
    }
    const closeTooltip = () => {
      setTooltipConfig({show: false, header: '', body: ''});
    }
    

    return (
    <div className='event-details'>
        <Loader config={loader}></Loader>

        <PicksSettings config={picksSettingsConfig} onHide={hidePicksSettings}></PicksSettings>
        <Tooltip config={tooltipConfig} onHide={closeTooltip}></Tooltip>
        <AddQuestion config={addQuestionModal} onHide={closeAddQuestionModal}></AddQuestion>
        <QuestionSettings config={questionSettingsModal} onHide={closeQuestionSettings}></QuestionSettings>
		{picks.submission?.user?.userId !== undefined && user.userId !== picks.submission?.user?.userId && (
			<div>impersonating user {picks.submission?.user?.displayName}</div>
		)}
        <div className='flex justify-content-between'>
			{user.isAdmin && (
			<Button variant='icon' onClick={openPicksSettings}>
				<SettingsIcon fontSize="small" />
			</Button>
			)}
        </div>

        <div className="adder" onClick={() => openAddQuestionModal(null)}>
			<div className="adder-line">
				<div className="adder-label"><AddIcon fontSize="small" />Add Question</div>
			</div>
        </div>
		
		{picks.length === 0 ? (<SkeletonQuestion></SkeletonQuestion>) : (<> 
		{picks.form?.section.length > 0 && picks.form.section.map(s => (<>
        <div className="dark-card section margin-inline-auto scrollable" key={`s${s.id}`} id={s.id}>

            { s.title!==undefined && (
            <div className="section-header">
                <h2>{s.title}</h2>

                {s.description?.length > 0 && (
                <Button variant="icon" onClick={(e) => showTooltip(e, s.title, s.description)} ><InfoOutlinedIcon fontSize="small" /></Button>
                )}
            </div>
            )}
            
            { s.question.length > 0 && s.question[0].id !== undefined && (
				s.question.map(q => (<>
				<div className='question-header'>
					<div className="flex justify-content-between align-items-center">
						<h3>{q.text}</h3>
						<div className='flex'>
							{q.description?.length > 0 && (
							<Button variant="icon" onClick={(e) => showTooltip(e, q.text, q.description)} ><InfoOutlinedIcon fontSize="small" /></Button>
							)}

						
							{user.isAdmin && (
							<Button variant='icon' onClick={(e) => openQuestionSettings(e, q.id)}>
								<SettingsIcon fontSize="small" />
							</Button>
							)}
						</div>
					</div>
				</div>

                {q.type === 'Driver Picks' && (
				<DriverPicks questionId={q.id} submissionId={picks.submission?.submissionId} disabled={false}></DriverPicks>
				)}


                {q.type === 'Constructor Picks' && (
				<ConstructorPicks questionId={q.id} submissionId={picks.submission?.submissionId} disabled={false}></ConstructorPicks>
                )}

				{q.type === 'Bonus Radio' && (
				<BonusRadioPicks questionId={q.id} submissionId={picks.submission?.submissionId} disabled={false}></BonusRadioPicks>
                )}
				</>))
			)}
		</div>
          

		<div className="adder" onClick={() => openAddQuestionModal(s.id)}>
			<div className="adder-line">
				<div className="adder-label"><AddIcon fontSize="small" />Add Question</div>
			</div>
		</div>
		</>))}
		</>)}
    </div>
    );
}

export default PicksTab;